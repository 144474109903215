.policies {
    .lst-kix_list_2-1>li {
        counter-increment: lst-ctn-kix_list_2-1;
    }

    .lst-kix_list_1-1>li {
        counter-increment: lst-ctn-kix_list_1-1;
    }

    .lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0;
    }

    .lst-kix_list_3-0>li::before {
        content: '\0025cf   ';
    }

    .lst-kix_list_5-7 {
        list-style-type: none;
    }

    .lst-kix_list_5-8 {
        list-style-type: none;
    }

    .lst-kix_list_3-1>li::before {
        content: 'o  ';
    }

    .lst-kix_list_3-2>li::before {
        content: '\0025aa   ';
    }

    .lst-kix_list_5-5 {
        list-style-type: none;
    }

    .lst-kix_list_5-6 {
        list-style-type: none;
    }

    .lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0;
    }

    .lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0;
    }

    .lst-kix_list_3-5>li::before {
        content: '\0025aa   ';
    }

    .lst-kix_list_5-0 {
        list-style-type: none;
    }

    .lst-kix_list_3-4>li::before {
        content: 'o  ';
    }

    .lst-kix_list_5-3 {
        list-style-type: none;
    }

    .lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0;
    }

    .lst-kix_list_3-3>li::before {
        content: '\0025cf   ';
    }

    .lst-kix_list_5-4 {
        list-style-type: none;
    }

    .lst-kix_list_5-1 {
        list-style-type: none;
    }

    .lst-kix_list_5-2 {
        list-style-type: none;
    }

    .lst-kix_list_3-8>li::before {
        content: '\0025aa   ';
    }

    .lst-kix_list_2-0>li {
        counter-increment: lst-ctn-kix_list_2-0;
    }

    .lst-kix_list_2-3>li {
        counter-increment: lst-ctn-kix_list_2-3;
    }

    .lst-kix_list_3-6>li::before {
        content: '\0025cf   ';
    }

    .lst-kix_list_3-7>li::before {
        content: 'o  ';
    }

    .lst-kix_list_1-2>li {
        counter-increment: lst-ctn-kix_list_1-2;
    }

    .lst-kix_list_2-2 {
        list-style-type: none;
    }

    .lst-kix_list_2-3 {
        list-style-type: none;
    }

    .lst-kix_list_5-0>li::before {
        content: '\0025cf   ';
    }

    .lst-kix_list_2-4 {
        list-style-type: none;
    }

    .lst-kix_list_2-5 {
        list-style-type: none;
    }

    .lst-kix_list_1-4>li {
        counter-increment: lst-ctn-kix_list_1-4;
    }

    .lst-kix_list_2-0 {
        list-style-type: none;
    }

    .lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0;
    }

    .lst-kix_list_2-1 {
        list-style-type: none;
    }

    .lst-kix_list_4-8>li::before {
        content: '\0025aa   ';
    }

    .lst-kix_list_5-3>li::before {
        content: '\0025cf   ';
    }

    .lst-kix_list_4-7>li::before {
        content: 'o  ';
    }

    .lst-kix_list_5-2>li::before {
        content: '\0025aa   ';
    }

    .lst-kix_list_5-1>li::before {
        content: 'o  ';
    }

    .lst-kix_list_4-8 {
        list-style-type: none;
    }

    .lst-kix_list_5-7>li::before {
        content: 'o  ';
    }

    .lst-kix_list_4-6 {
        list-style-type: none;
    }

    .lst-kix_list_5-6>li::before {
        content: '\0025cf   ';
    }

    .lst-kix_list_5-8>li::before {
        content: '\0025aa   ';
    }

    .lst-kix_list_4-7 {
        list-style-type: none;
    }

    .lst-kix_list_4-0 {
        list-style-type: none;
    }

    .lst-kix_list_4-1 {
        list-style-type: none;
    }

    .lst-kix_list_5-4>li::before {
        content: 'o  ';
    }

    .lst-kix_list_4-4 {
        list-style-type: none;
    }

    .lst-kix_list_5-5>li::before {
        content: '\0025aa   ';
    }

    .lst-kix_list_2-6 {
        list-style-type: none;
    }

    .lst-kix_list_4-5 {
        list-style-type: none;
    }

    .lst-kix_list_2-7 {
        list-style-type: none;
    }

    .lst-kix_list_4-2 {
        list-style-type: none;
    }

    .lst-kix_list_2-8 {
        list-style-type: none;
    }

    .lst-kix_list_4-3 {
        list-style-type: none;
    }

    .lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0;
    }

    .li-bullet-2::before {
        margin-left: -14.2pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 14.2pt;
    }

    .lst-kix_list_2-5>li {
        counter-increment: lst-ctn-kix_list_2-5;
    }

    .lst-kix_list_2-8>li {
        counter-increment: lst-ctn-kix_list_2-8;
    }

    .lst-kix_list_2-2>li {
        counter-increment: lst-ctn-kix_list_2-2;
    }

    .lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0;
    }

    .lst-kix_list_1-3 {
        list-style-type: none;
    }

    .lst-kix_list_1-4 {
        list-style-type: none;
    }

    .lst-kix_list_2-6>li::before {
        content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.' counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '.' counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.' counter(lst-ctn-kix_list_2-6, decimal) ' ';
    }

    .lst-kix_list_2-7>li::before {
        content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.' counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '.' counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.' counter(lst-ctn-kix_list_2-6, decimal) '.' counter(lst-ctn-kix_list_2-7, decimal) ' ';
    }

    .lst-kix_list_2-7>li {
        counter-increment: lst-ctn-kix_list_2-7;
    }

    .lst-kix_list_1-5 {
        list-style-type: none;
    }

    .lst-kix_list_1-6 {
        list-style-type: none;
    }

    .lst-kix_list_1-0 {
        list-style-type: none;
    }

    .lst-kix_list_2-4>li::before {
        content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.' counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '.' counter(lst-ctn-kix_list_2-4, decimal) ' ';
    }

    .lst-kix_list_2-5>li::before {
        content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.' counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '.' counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) ' ';
    }

    .lst-kix_list_2-8>li::before {
        content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.' counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) '.' counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.' counter(lst-ctn-kix_list_2-6, decimal) '.' counter(lst-ctn-kix_list_2-7, decimal) '.' counter(lst-ctn-kix_list_2-8, decimal) ' ';
    }

    .lst-kix_list_1-1 {
        list-style-type: none;
    }

    .lst-kix_list_1-2 {
        list-style-type: none;
    }

    .lst-kix_list_3-7 {
        list-style-type: none;
    }

    .lst-kix_list_3-8 {
        list-style-type: none;
    }

    .lst-kix_list_3-1 {
        list-style-type: none;
    }

    .lst-kix_list_3-2 {
        list-style-type: none;
    }

    .lst-kix_list_3-0 {
        list-style-type: none;
    }

    .lst-kix_list_1-7 {
        list-style-type: none;
    }

    .lst-kix_list_3-5 {
        list-style-type: none;
    }

    .lst-kix_list_1-7>li {
        counter-increment: lst-ctn-kix_list_1-7;
    }

    .lst-kix_list_1-8 {
        list-style-type: none;
    }

    .lst-kix_list_3-6 {
        list-style-type: none;
    }

    .lst-kix_list_3-3 {
        list-style-type: none;
    }

    .lst-kix_list_3-4 {
        list-style-type: none;
    }

    .lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0;
    }

    .li-bullet-1::before {
        margin-left: -14.2pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 14.2pt;
    }

    .lst-kix_list_4-0>li::before {
        content: '\0025cf   ';
    }

    .lst-kix_list_2-6>li {
        counter-increment: lst-ctn-kix_list_2-6;
    }

    .lst-kix_list_4-1>li::before {
        content: 'o  ';
    }

    .lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0;
    }

    .lst-kix_list_4-4>li::before {
        content: 'o  ';
    }

    .lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0;
    }

    .lst-kix_list_1-5>li {
        counter-increment: lst-ctn-kix_list_1-5;
    }

    .lst-kix_list_4-3>li::before {
        content: '\0025cf   ';
    }

    .lst-kix_list_4-5>li::before {
        content: '\0025aa   ';
    }

    .lst-kix_list_4-2>li::before {
        content: '\0025aa   ';
    }

    .lst-kix_list_4-6>li::before {
        content: '\0025cf   ';
    }

    .lst-kix_list_1-8>li {
        counter-increment: lst-ctn-kix_list_1-8;
    }

    .lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0;
    }

    .lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0;
    }

    .lst-kix_list_2-4>li {
        counter-increment: lst-ctn-kix_list_2-4;
    }

    .lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0;
    }

    .lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0;
    }

    .lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0;
    }

    .lst-kix_list_1-0>li::before {
        content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
        font-weight: 700;
    }

    .lst-kix_list_1-1>li::before {
        content: '' counter(lst-ctn-kix_list_1-1, lower-latin) '. ';
    }

    .lst-kix_list_1-2>li::before {
        content: '' counter(lst-ctn-kix_list_1-2, lower-roman) '. ';
    }

    .lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0;
    }

    .lst-kix_list_1-3>li::before {
        content: '' counter(lst-ctn-kix_list_1-3, decimal) '. ';
    }

    .lst-kix_list_1-4>li::before {
        content: '' counter(lst-ctn-kix_list_1-4, lower-latin) '. ';
    }

    .lst-kix_list_1-0>li {
        counter-increment: lst-ctn-kix_list_1-0;
    }

    .lst-kix_list_1-6>li {
        counter-increment: lst-ctn-kix_list_1-6;
    }

    .lst-kix_list_1-7>li::before {
        content: '' counter(lst-ctn-kix_list_1-7, lower-latin) '. ';
    }

    .lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0;
    }

    .lst-kix_list_1-3>li {
        counter-increment: lst-ctn-kix_list_1-3;
    }

    .lst-kix_list_1-5>li::before {
        content: '' counter(lst-ctn-kix_list_1-5, lower-roman) '. ';
    }

    .lst-kix_list_1-6>li::before {
        content: '' counter(lst-ctn-kix_list_1-6, decimal) '. ';
    }

    .li-bullet-0::before {
        margin-left: -13.5pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 13.5pt;
    }

    .lst-kix_list_2-0>li::before {
        content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
    }

    .lst-kix_list_2-1>li::before {
        content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) ' ';
    }

    .lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0;
    }

    .lst-kix_list_1-8>li::before {
        content: '' counter(lst-ctn-kix_list_1-8, lower-roman) '. ';
    }

    .lst-kix_list_2-2>li::before {
        content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.' counter(lst-ctn-kix_list_2-2, decimal) ' ';
    }

    .lst-kix_list_2-3>li::before {
        content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.' counter(lst-ctn-kix_list_2-2, decimal) '.' counter(lst-ctn-kix_list_2-3, decimal) ' ';
    }

    ol {
        margin: 0;
        padding: 0;
    }

    table td,
    table th {
        padding: 0;
    }

    .c10 {
        margin-left: 40.5pt;
        padding-top: 0;
        padding-left: -4.5pt;
        padding-bottom: 0;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c27 {
        color: #2f5496;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Calibri';
        font-style: normal;
    }

    .c2 {
        color: #000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Calibri';
        font-style: normal;
    }

    .c15 {
        color: #000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: 'Calibri';
        font-style: italic;
    }

    .c6 {
        color: #000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Calibri';
        font-style: normal;
    }

    .c5 {
        color: #7030a0;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Calibri';
        font-style: italic;
    }

    .c28 {
        margin-left: 54pt;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c4 {
        color: #7030a0;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Calibri';
        font-style: italic;
    }

    .c3 {
        color: #000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Calibri';
        font-style: italic;
    }

    .c7 {
        color: #7030a0;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Calibri';
        font-style: normal;
    }

    .c19 {
        color: #2f5496;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Times New Roman';
        font-style: normal;
    }

    .c12 {
        color: #000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Calibri';
        font-style: italic;
    }

    .c20 {
        color: #2f5496;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Times New Roman';
    }

    .c11 {
        padding-top: 0;
        padding-bottom: 8pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: center;
    }

    .c16 {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: right;
    }

    .c23 {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    .c1 {
        padding-top: 0;
        padding-bottom: 8pt;
        line-height: 1.0791666666666666;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c22 {
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: 'Times New Roman';
    }

    .c21 {
        text-decoration-skip-ink: none;
        -webkit-text-decoration-skip: none;
        color: #0563c1;
        text-decoration: underline;
    }

    .c26 {
        text-decoration-skip-ink: none;
        -webkit-text-decoration-skip: none;
        text-decoration: underline;
    }

    .c29 {
        margin-left: 0;
        list-style-position: inside;
    }

    .c13 {
        background-color: #fff;
        max-width: 523.3pt;
        padding: 36pt;
    }

    .c8 {
        margin-left: 18pt;
        padding-left: -3.8pt;
    }

    .c24 {
        margin-left: 18pt;
        padding-left: -4.5pt;
    }

    .c0 {
        padding: 0;
        margin: 0;
    }

    .c25 {
        margin-left: 40.5pt;
        padding-left: -4.5pt;
    }

    .c9 {
        color: inherit;
        text-decoration: inherit;
    }

    .c18 {
        font-style: italic;
    }

    .c14 {
        height: 11pt;
    }

    .c17 {
        color: #7030a0;
    }

    .title {
        padding-top: 24pt;
        color: #000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: 'Calibri';
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    .subtitle {
        padding-top: 18pt;
        color: #666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: 'Georgia';
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    li {
        color: #000;
        font-size: 11pt;
        font-family: 'Calibri';
    }

    p {
        margin: 0;
        color: #000;
        font-size: 11pt;
        font-family: 'Calibri';
    }

    h1 {
        padding-top: 24pt;
        color: #000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: 'Calibri';
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h2 {
        padding-top: 18pt;
        color: #000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: 'Calibri';
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h3 {
        padding-top: 14pt;
        color: #000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: 'Calibri';
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h4 {
        padding-top: 12pt;
        color: #000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: 'Calibri';
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h5 {
        padding-top: 11pt;
        color: #000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: 'Calibri';
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    h6 {
        padding-top: 10pt;
        color: #000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: 'Calibri';
        line-height: 1.0791666666666666;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

}
