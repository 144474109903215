// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$smoky-black: rgb(9, 10, 10); //#090a0a
$manatee: rgb(155, 160, 161); //#9ba0a1
$la-salle-green: rgb(0, 113, 37); //#007125
$eerie-black: rgb(22, 24, 25); //#161819
$stormcloud: rgb(88, 96, 99); //#586063
$gunmetal: rgb(44, 48, 50); //#2c3032
$gunmetal2: rgb(47, 52, 55); //#2f3437
$red-orange: rgb(255, 77, 79); //#ff4d4f
$gainsboro: rgb(222, 223, 224); //#dedfe0
$jet: rgb(51, 51, 51); //#333333
$anti-flash-white: rgb(242, 242, 242);//#f2f2f2
$anti-flash-white1: rgb(239, 240, 240); // #eff0f0
$seashell: rgb(255, 238, 238); //	#ffeeee
$green-pantone: rgb(0, 162, 53); // #00a235
$grayx11: rgb(188, 191, 193); // #bcbfc1
$gainsboro2: rgb(217, 217, 217); // #d9d9d9
$white-smoke: rgb(245, 245, 245); // #f5f5f5
$snow: rgb(251, 251, 251); //#fbfbfb
$white-smoke2: rgb(247, 247, 247); // #f7f7f7
$raisin-black: rgb(33, 33, 33); //#212121
$alice-blue: rgb(234, 240, 255); //#eaf0ff
$blue-crayola: rgb(40, 95, 250); //#285ffa
$isabelline: rgb(230, 241, 234); //#e6f1ea
$mountain-meadow: rgb(41, 204, 151); //#29cc97
$platinum: rgb(226, 226, 226); //#e2e2e2

// Overlays
$white-overlay04: rgba($white, 0.4);
$raisin-black007: rgba($raisin-black, 0.07);
$black-012: rgba($black, 0.12);
$black0043: rgba($black, 0.043);
// Linears
$primary-linear: linear-gradient(88.54deg, $la-salle-green -1.55%, $green-pantone 100.28%);
