.t-mainLayout {
    max-width: rem(1366);
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;

    &_inApp_desc {
        margin-top: rem(16);
    }

    &_expired {
        position: fixed;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 101;
        padding: rem(32) rem(48);
        background-color: $white;
        border-radius: rem(8);

        &_button {
            margin-top: rem(24);
        }
    }
}
